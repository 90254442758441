<template id="app">
  <div id='topbar'>
    <router-link  id="you" to='/you'>{{youText}}</router-link>
    <router-link to='/' id="appName">Corridor</router-link>
    <!-- <router-link  class="sides" to='/create'>Search</router-link> -->
  </div>

  <router-view/>
</template>

<script type="text/javascript">
  import {auth} from '@/firebase/firebase.js'
  export default{
    data(){
      return{
        youText: 'Login'
      }
    },

    async created(){
      await auth.onAuthStateChanged((user) => {
        if (user) {
          this.youText = 'You'
        }
      });
    }
  }
</script>

<style>
  :root{
    --col1 : #FAE73C;
    --col2 : black;
    --light: white;
    --dark : black;

  }
  body{
    background-color: var(--light);
    margin: 0;
  }
  html{
    height: 100%;
  }
  #app{
    height: 100vh;
    width: 100%;
  }
  h1{
    padding: 0;
    margin: 0;
  }
  p{
    margin: 0;
  }
  a{
    color: var(--dark);
    text-decoration: none;
    font-size: 2.34vh;
    display: block;
  }
  a:hover{
    text-decoration: underline;
    font-weight: bold;
  }

  .button{
    border: .34vh solid var(--dark);
    margin: .55vh;
    padding: .55vh;
    text-align: center;
    font-size: 2.22vh;
  }
  .button:hover{
    border-width: .55vh;
    cursor: pointer;
  }

  .input{
    font-size: 2.22vh;
    padding: .22vh;
    margin: .22vh;
    text-align: center;
    font-weight: bold;
  }
  .input:focus{
    border: .55vh solid var(--dark);
  }

  .title{
    font-size: 3.34vh;
    font-weight: bold;
    margin: .89vh;
  }


  /*This PAge*/
  #topbar {
    margin-right: 5vw;
    margin-left: 5vw;
    border-bottom: 5px solid var(--dark);

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #appName {
    font-size: 5vh;
    cursor: pointer;
    user-select: none;
    font-weight: bold;
    flex-grow: 1;
    text-align: center;
  }
  #you{
    user-select: none;
  }
</style>
