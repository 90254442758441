export default class Corridor {
	constructor(){
		this.corridorId = null,
		this.creatorTag = null,
		this.isPublic = true,
		this.tags = [],
		this.numberOfVisit = 0,
		this.dateModified  = null,
		this.title = null,
		this.walls = [],
		this.oldestFirst = false
	}
}