<template>
	<Explorer 
		:who="who"
		:edit="false"
	/>
</template>

<script type="text/javascript">
	import Explorer from '@/components/explorer/Explorer.vue'

	export default{
		name: 'ExplorerView',
		components: {
			Explorer
		},
		props:['who'], 
		data(){
			return {
			}
		},
	}
</script>