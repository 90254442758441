import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import CreationView from '../views/CreationView.vue'
import ViewerView from '../views/ViewerView.vue'
import ExplorerView from '../views/ExplorerView.vue'
import YouView from '../views/YouView.vue'



const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  }, //home
  {
    path: '/you',
    name: 'you',
    component: YouView
  }, //you
  {
    path: '/create/:corridorId?',
    name: 'create',
    component: CreationView,
    props: true,
  }, //create
  {
    /*first is for the first time the corridor is viewed after creation to handle closing it*/
     path: '/view/:id/:first?',
     name: 'view',
     component: ViewerView,
     props: (route) => ({
       id: route.params.id,
     }),
   }, //view
  {
    path: '/explorer/:who',
    name: 'explorer',
    component: ExplorerView,
    props: true,
  }, //explorer
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  }  //about
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
