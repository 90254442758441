<template>
	<div id='tDViewer'> 
		<AWall
			v-for='wall in walls'
			:theWall='wall'
			canFullScreen="true"
		/>
	</div>
</template>
<script type="text/javascript">
	import AWall from '@/components/viewer/AWall.vue'
	export default {
		name: 'TopDownViewer',
		components: {
			AWall
		},
		props: {
			walls: Array,
		},
		data(){
			return {
			}
		},
		methods: {

		}
	}
</script>

<style type="text/css" scoped>
	#tDViewer{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.aWall {
		height: 89%;
	}
</style>