<template>
	<div id="viewer">

		<!-- close button -->
		<i @click="onClose"
			class="button fa fa-window-close fa-xl"
			id="closeButton"
			></i> 
		<!-- Selector -->
		<div id="viewerSelector">
		    <p 
		    	class="type" 
		    	:class="{ 'selectedViewer': viewerType === 'topDown' }" 
		    	@click="viewerSelected('topDown')">
		    TopDown</p>
		    <p 
		    	class="type" 
		    	:class="{ 'selectedViewer': viewerType === 'carousel' }" 
		    	@click="viewerSelected('carousel')">
		    Carousel</p>
		    <p 
		    	class="type" 
		    	:class="{ 'selectedViewer': viewerType === 'corridor' }" 
		    	@click="viewerSelected('corridor')">
		    Corridor</p>
		    <p 
		    	class="type" 
		    	:class="{ 'selectedViewer': viewerType === 'grid' }" 
		    	@click="viewerSelected('grid')">
		    Grid</p>
		 </div>

		<!-- selected viewer -->
		<TopDownViewer
			:walls='corridor.walls'
			v-if="viewerType==='topDown'"
		/>
		<CarouselViewer
			:walls='corridor.walls'
			v-if="viewerType==='carousel'"
		/>
		<CorridorViewer 
			v-if="viewerType==='corridor' && corridor.walls"
			:walls='corridor.walls'
		/>
		<GridViewer 
			:walls='corridor.walls'
			v-if="viewerType==='grid'"
		/>
	</div>
</template>
<script type="text/javascript">
	import TopDownViewer from '@/components/viewer/TopDownViewer.vue'
	import CarouselViewer from '@/components/viewer/CarouselViewer.vue'
	import CorridorViewer from '@/components/viewer/CorridorViewer.vue'
	import GridViewer from '@/components/viewer/GridViewer.vue'
	import Corridor from '@/classes/corridorClasse.js'

	export default {
		name: 'Viewer',
		components: {
			TopDownViewer,
			CarouselViewer,
			CorridorViewer,
			GridViewer,
		},
		props: {
			corridor: Object,
			isPreview: false,
		},
		emits:['close'],
		data(){
			return {
				viewerType: 'corridor',
			}
		},
		methods: {
			viewerSelected(type){
				this.viewerType = type;
			},
			onClose(){
				if(this.isPreview){
					this.$emit('close');
				}
				//for the first view after creation
				else if (this.$route.params.first == '1'){
					this.$router.go(-2)
				}
				else{
					if(window.history.length < 3){
						//from in a new tab ... go to home
						this.$router.push('/');
					}else {
						//not in a new tab maybe from appercu go back where it comes from
						this.$router.back();
					}
				}
			},
		},
	}
</script>

<style type="text/css" scoped>
	#closeButton{
		z-index: 4;
		font-weight: bold;
		border: none;

		position: fixed;
		top: 2vh;
		right: 2vh;

		user-select: none;
		font-size: 3.22vh;
	}

	#viewer{
		overflow: hidden;
		display: flex;
		flex-direction: column;
		background-color: var(--dark);
		color: var(--light);

		position: fixed;
		height: 100%;
		width: 100%;
		top: 0;

		z-index: 2;
		overflow: auto;
		overflow-x: hidden;
	}

	#viewerSelector{
		display: inline-flex;
		flex-direction: row;
		height: 6vh;
		margin-top: 2vh;
		width: 100vw;
		z-index: 3;
		justify-content: center;
	}
	.type{
		text-decoration: underline;
		font-size: 2.22vh;
		padding: 1vh;
   		user-select: none;
	}
	.type:hover{
		cursor: pointer;
		font-size: 2.34;
		font-weight: bold;
	}
	.selectedViewer{
		color: var(--col1);
		font-weight: bold;
	}

	@media only screen and (max-width: 1200px) {
	  #closeButton {
	  	top: 1.44vh;
	  	right: 1vw;
	  	
	  }
	}
	@media only screen and (max-width: 600px) {
	  #viewerSelector{
	  	justify-content: flex-start;
	  }
	}
</style>