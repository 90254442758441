<template>
  <div class="aWall">
    <!-- close button -->
    <i
      v-if="showClose" 
      @click="onClose"
      class="button fa fa-window-close fa-xl"
      id="closeButton"
      ></i> 

    <h1 class="wallTitle">{{ theWall.title }}</h1>
    <Image 
      class="uneImage"
      :sauce="theWall.imgURL" 
      :canFullScreen="canFullScreen"
      />
  </div>
</template>

<script>
  import Image from '@/components/utils/Image.vue'

  export default {
    name: 'AWall',
    components: {
      Image
    },
    props: {
      theWall: Object,
      canFullScreen: false,
      showClose: false,
    },
    emits: ['close'],
    data() {
      return {
      };
    },
    methods: {
      onClose(){
        this.$emit('close')
      }
    },
  };
</script>

<style scoped>
  #closeButton{
    z-index: 4;
    font-weight: bold;
    border: none;

    position: absolute;
    top: .69vh;
    right: .69vh;

    user-select: none;
    font-size: 3.22vh;
  }

  .aWall {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 5px solid var(--light);
    margin: 2vh;
    padding: 1.22vh;
    width: 55vw;
    height: 89vh;
  }
  .wallTitle {
    text-align: center;
    font-size: 3.34vh;
    color: var(--light);
    text-decoration: underline;
  }

  /*IMAEG!!!!*/
  .uneImage{
    height: 90%;
  }

  @media only screen and (max-width: 1200px) {
    .aWall {
      width: 89vw;
      height: 74vh;
    }
  }
</style>
