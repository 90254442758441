<template>
	<Explorer who='all' :edit='false'/>

</template>
<script type="text/javascript">
	import Explorer from '@/components/explorer/Explorer.vue'

	export default{
		name: 'Home',
		components: {
			Explorer,
		},
		data(){
			return {
			}
		}
	}
</script>