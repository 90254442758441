<template>
  <div id="box">
  	<div id="sureBox">
  	  <p id="message">Are you sure you want to delete this {{ what }}?</p>
  	  <p
  	  	id="clickAgain"
  	  	v-if="clickAgain"
  	  	>Click Again To Confirm Deletion <br/> For EVER
  	  </p>
  	  <div id="yesOrNo">
  	    <p id="yes" class="answer" @click="onYes">Yes</p>
  	    <p class="answer" @click="onNo">No</p>
  	  </div>
  	</div>
  </div>
</template>

<script>
	export default {
	  name: 'Sure',
	  props: ['what'],
	  data(){
	  	return{
	  		clickAgain: false,
	  	}
	  },
	  emits: ['yes', 'no'],
	  methods: {
	  	onYes(){
	  		if (this.clickAgain){
	  			//emit delete
	  			this.$emit('yes');
	  		}
	  		else{
	  			this.clickAgain = true;
	  		}
	  	},
	  	onNo(){
	  		this.clickAgain = false;
	  		this.$emit('no');
	  	}
	  }
	};
</script>

<style scoped>
	#box {
	  position: fixed; /* Use fixed position to cover the entire viewport */
	  top: 0;
	  left: 0;
	  width: 100vw;
	  height: 100vh;
	  z-index: 3;
	}

	#sureBox {
	  border: 2vh solid red;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translate(-50%, -50%);
	  padding: 5vh;
	  text-align: center; /* Center text horizontally */
	  background-color: whitesmoke;
	}

	#message{
		font-size: 3vh;
		font-weight: bold;
	}

	#clickAgain{
		margin: 2vh;
		font-size: 2.22vh;
		font-weight: bold;
		color: red;
	}

	#yesOrNo{
		margin: 3vh;
	}

	.answer {
	  display: inline-block; /* Make the answers appear in a line */
	  border: .89vh solid black;
	  padding: 2vh;
	  font-size: 2vh;
	  margin: 1vh; /* Add some spacing between the answers */
	  cursor: pointer;
	}
	.answer:hover{
		border-width: 1.22vh;
		margin: .78vh;
	}

	#yes:hover{
		border-color: red;
	}

	/*Mobile*/
	@media only screen and (max-width: 1000px) {
		#sureBox{
			width: 69vw;
		}
	}
</style>
