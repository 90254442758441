<template>
	<div
		class="apercu"
		@click="goTo()">

		<h1>{{corridorInfos.title}}</h1>

		<!-- Creator -->
		<div class="info">
			<i class="label">By: </i>
			<i 
				class="creatorTag" 
				@click.stop="onCreatorTag"
				> {{corridorInfos.creatorTag}} 
			</i>
		</div>
		<!-- Date -->
		<div class="info">
			<i class="label">Last Modified:</i>
			<i>{{"  " + corridorInfos.displayDate}} </i>
		</div>
		
		<!-- number of walls and visits -->
		<div class="wallsAndVisits">
			<!-- Visit -->
			<div class="info">
				<i>{{corridorInfos.numberOfVisit}}</i>
				<i class="label eye fas fa-eye"></i>
			</div>
			<!-- number of walls -->
			<div class="info">
				<i>{{corridorInfos.walls.length}} Walls</i>
			</div>	
		</div>

		<!-- EDIT -->
		<router-link 
			v-if='edit' 
			@click='editClicked=true'
			class="button edit" 
			:to="'/create/' + corridorInfos.corridorId"
			>Edit
		</router-link>
	</div>
</template>

<script type="text/javascript">
	import {fetchCorridor} from '@/firebase/firebase.js'
	export default{
		name: 'Apercu',
		props: {
			corridorInfos: Object,
			edit: Boolean,
			editClicked: false,
		},
		methods:{
			goTo(){
				if (!this.editClicked) {
				  this.$router.push({
				    name: 'view',
				    params: {
				      id: this.corridorInfos.corridorId,
				    },
				  });
				}
			},
			onCreatorTag(){
				if(!this.edit){
					this.$router.push('/explorer/'+this.corridorInfos.creatorTag)
					return
				}
			},
		},
		mounted(){
		}
	}
</script>

<style type="text/css" scoped>
	.apercu{
		border: .89vh solid var(--dark);
		border-radius: .89vh;
		padding: 3vw;
		cursor: pointer;
		z-index: 1;
	}
	.apercu:hover{
		transform: scale(1.01);
	}

	.edit{
		z-index: 2;
	}
	.info{
		margin: .55vh;
		padding-bottom: 1vh;
	}

	.wallsAndVisits{
		display: flex;
		justify-content: space-evenly;
		width: 55%;

	}

	.label{
		text-decoration: underline;
		font-weight: bold;
	}
	.eye{
		padding-left: .55vw;
		text-decoration: none;
	}

	.creatorTag{
		font-size: 3vh;
		margin: 2vh;
		z-index: 3;
		cursor: pointer;
	}
	.creatorTag:hover{
		border: .22px solid var(--dark);
		padding: .22vh;
	}


	@media only screen and (max-width: 700px){
		.apercu{
			width: 76vw;
		}
		.apercu:hover{
			transform: scale(1);
		}
		.creatorTag:hover{
		}

	}
</style>