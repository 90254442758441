<template>
	<!-- create image and title   -->
	<div 
		class="card"
		@drop.prevent="handleDrop"
      	@dragover.prevent
		>

		<!-- Delete Wall -->
		<p 
		  class="button deleteWall"
		  @click="deleteClicked"
		  >Delete Wall
		</p>

		<!-- Title -->
		<input 
			type='text' 
			placeholder="Wall Title" 
			v-model='newWall.title'
			maxlength="22"
			class="input"
			@input="changedTitle(index)">

		<!-- image upload -->
		<div id="imageUpload">

			<label
				class="imageInputLabel"
				:for="'imageInput'+index"
				>Chosse An Image
			</label>
			<i class="dropIt"
				v-if="!isMobile"
				>Or drop it here</i>
			<input 
				type="file" 
				name="file"
				:id="'imageInput'+index" 
				class="iInput" 
				accept="image/*" 
				@change="onFileSelected"
				:multiple="false"
			>

			<!-- preview -->
			<Image
				class="uneImage"
				:sauce="newWall.imgURL"
				:canFullScreen="true"
				/>
		</div>
	</div>

	<!-- sure to delete ? -->
	<Sure 
	  v-if="showSure"
	  what="Wall"
	  @no="showSure=false"
	  @yes="deleteWall"
	/>
</template>

<script type="text/javascript">
	import Image from '@/components/utils/Image.vue'
	import {uploadImage} from '@/firebase/firebase.js'
    import Sure from '@/components/utils/Sure.vue'

	export default{
		name: 'CreationCard',
		props: {
			wall: Object,
			index: Number,
		},
		components: {
			Sure,
			Image,
		},
		data(){
			return{
				newWall: {
					title: '', 
					imgURL: "", 
					description: "",
				},
				selectedFile: '',
				showPreview: false,
				upload: "Select a File",
				isFullScreen: false,

				showSure: false,
				isMobile: false
			}
		},
		emits: ['changedTitle', 'imageChanged', 'isUploading', 'deleted', 'notUploaded'],
		methods: {
			async onFileSelected(event){
				//the file is inside the event
				this.selectedFile = event.target.files[0];
				//send file to creationView
				this.$emit('imageChanged', this.selectedFile)
				this.newWall.imgURL = URL.createObjectURL(event.target.files[0]);
			},

			//for drop image
			handleDrop(){
				const file = event.dataTransfer.files[0];
				this.processFile(file);
			},
			async processFile(file) {
		      if (file && this.isImage(file)) {

		      	const reader = new FileReader();
				reader.onload = (e) => {
					//set preview
		      		this.newWall.imgURL = e.target.result;
				};
				reader.readAsDataURL(file);

				this.selectedFile = file;
				//send file to creationView
				this.$emit('imageChanged', this.selectedFile)
		      } else {
		        alert('Please select a valid image file. Accepted types: .jpeg , .jpg , .png , .gif , .bmp ');
		        console.log(file.type)
		      }
		    },
		    isImage(file) {
		      const acceptedImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp'];
		      return file && acceptedImageTypes.includes(file.type);
		    },

			changedTitle(index){
				this.$emit('changedTitle', this.newWall.title)
			},

			//full screen 
			toggleFullScreen() {
				if(this.newWall.imgURL !== '' || this.upload === "Upload Image"){
			  		this.isFullScreen = !this.isFullScreen;
				}
			},
			exitFullScreen() {
			  this.isFullScreen = false;
			},

			//delete Wall
			deleteClicked(){
			  //when delete button is clicked verify deletion
			  this.showSure = true;
			},
			deleteWall(){
				//will be removed in CreationView
				this.$emit('deleted');
				this.showSure = false;
			},
		},
		mounted(){
			this.newWall.title = this.wall.title;
			if (this.wall.imgURL){
				this.newWall.imgURL = this.wall.imgURL
				this.showPreview = true;
			}
		},
		beforeMount(){
		  //mobile verif 
		  this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		}		
	}
</script>

<style scoped>
	.card{
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		border: 5px solid var(--dark);

		margin: 2vh;
		padding: 1vh;
		padding-bottom: 2vh;

		width: 55vw;
		height: 89vh;
	}

	.deleteWall{
	  position: absolute;
	  top: .69vh;
	  right: .69vh;
	}
	.deleteWall:hover{
	  border-color: red;
	  font-weight: bold;
	}

	.imageInputLabel{
		text-align: center;
		cursor: pointer;
		padding: 1vh;
		margin-top: 1vh;
		background-color: var(--col1);
		border: 3.4px solid var(--dark);
		border-radius: 6.8px;
	}
	.iInput{
		opacity: 0;
		position: absolute;
		z-index: -1;
	}

	#imageUpload{
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		margin-top: 2.22vh;
		height: 90%;
	}

	.uneImage{
	  height: 80%;
	}
	.dropIt{
		margin: .89vh;
	}

	

	@media only screen and (max-width: 1200px){
		.card{
			width: 93vw;
			height: 74vh;
			padding: .22vw;
		}
		.input{
			margin-top: 6vh;
		}
		#imageUpload{
			margin-top: 1vh;
		  	height: 85%;
		}
	}
</style>