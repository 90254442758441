<template>
  <div class="image-container">
    <img
      @click="goFull" 
      :src="sauce" 
      class="image" 
      :class="{'currentImage': canFullScreen  }"
      ref="image" 
      />
  </div>
</template>

<script>
  export default {
    name: 'Image',

    props: {
      sauce: String,
      canFullScreen: false,
    },
    data() {
      return {
        isFullScreen: false
      }
    },

    methods: {
      goFull() {
        if (this.canFullScreen) {
          // Exit full-screen mode
          if (document.fullscreenElement) {
            document.exitFullscreen();
          }
          //enter full screen
          else{
            const imageElement = this.$refs.image;
            if (imageElement) {
              imageElement.requestFullscreen();
              this.isFullScreen = true;
            }
          }
          return
        }
        // Add event listener for Escape key
        document.addEventListener('keydown', this.handleEscapeKey);
      },
      handleEscapeKey(event) {
        if (event.key === 'Escape') {
          // Exit full-screen mode
          if (document.fullscreenElement) {document.exitFullscreen()};
          document.removeEventListener('keydown', this.handleEscapeKey);
        }
      },
    },

    mounted(){
    },
  }
</script>

<style scoped>
  .image-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Use the full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image {
    max-width: 100%;
    max-height: 100%;
  }

  .currentImage:hover {
    transform: scale(1.02);
    cursor: zoom-in;
  }
</style>
