// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAVcyS5FtXiWrezXY3rhY8p5DvRonzeEl0",
  authDomain: "corridor-5353b.firebaseapp.com",
  projectId: "corridor-5353b",
  storageBucket: "corridor-5353b.appspot.com",
  messagingSenderId: "671200102495",
  appId: "1:671200102495:web:614ed190b3b0de135e73ea"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// --------------------------------------------------------
//db
// --------------------------------------------------------
  import {getFirestore, doc, setDoc, addDoc, getDoc, getDocs, updateDoc, deleteDoc, collection, query, where, orderBy, FieldValue } from 'firebase/firestore/lite';
  const db = getFirestore(app);

  /*Add a corridor to the corridors collection in db if existing replace else create a new*/
  async function publishCorridor(corridorObj, isNew){
    //set date modified to now in unix
    corridorObj.dateModified = new Date().getTime();
    /*if creating corridor*/
    if(isNew){
      const corridor = await addDoc(collection(db, '/corridors/'), corridorObj);
      const id = corridor.id;
      setField('/corridors/', id, 'corridorId', id);
      return id
    }
    /*If updating a existing Corridor*/
    else if(!isNew){
      const parent = doc(db, '/corridors/', corridorObj.corridorId)
      await setDoc (parent, corridorObj, {merge: true})
      return corridorObj.corridorId
    }
    else{
      return 22;
    }
  }

  /*Fetch a corridor with a given Id*/
  async function fetchCorridor(corridorId){
    const docRef = doc(db, '/corridors/', corridorId);
    const docSnap = await getDoc(docRef);
    const theDoc = docSnap.data();
    return theDoc;
  }

  // change the value of a field in a doc take the doc path docName and the new value
  async function setField(path, docName, fieldName, newValue){
    const docRef = doc(db, path, docName);
    await updateDoc(docRef, {[fieldName]:newValue})
  }

  //make date from unix
 function formatUnixDateToLocalString(unixTimestamp) {
   const date = new Date(unixTimestamp);
   if (isNaN(date.getTime())) {
     return "Un jour quelquonc . . .";
   }

   const options = {
     day: "2-digit",
     month: "short",
     year: "numeric",
     hour: "2-digit",
     minute: "2-digit",
     hour12: false,
     timeZoneName: "short"
   };

   return date.toLocaleDateString("en-US", options);
 }
  //return all public corridor
  async function fetchAllCorridors(){
    const corridorsRef = collection(db, '/corridors/')
    const q = query(corridorsRef, where('isPublic', '!=', false), orderBy("isPublic"))
    const dataSnap = await getDocs(q)

    var corridorsList = []
    dataSnap.forEach((d) => {
      corridorsList.push(d.data())
    })
    //make date
    corridorsList.forEach(corridor => {
      corridor.displayDate = formatUnixDateToLocalString(corridor.dateModified);
    });

    return corridorsList
  }
  async function fetchTagedCorridor(tag){
    if (tag!=null){
      const corridorsRef = collection(db, '/corridors');
      const q = query(corridorsRef, where('creatorTag', '==', tag));
      const dataSnap = await getDocs(q)

      var corridorsList = []
      dataSnap.forEach((d) => {
        corridorsList.push(d.data())
      })
      //make date
      corridorsList.forEach(corridor => {
        corridor.displayDate = formatUnixDateToLocalString(corridor.dateModified);
      });
      
      return corridorsList
    }
    else{
      console.log("wrong user tag " + tag)
      return [] 
    }
  }


  // DELETE Corridor
  async function deleteCorridor(corridorObj) {
    try {
      for (const wall of corridorObj.walls) {
        const url = wall.imgURL;

        if (url) {
          // Now, delete the image using the storageRef
          await deleteImage(url);
        }
      }

      const corridorRef = doc(db, 'corridors', corridorObj.corridorId);
      await deleteDoc(corridorRef);
      return true; // Successful deletion

    } catch (error) {
      console.error('Error deleting corridor:', error);
      return false; // Failed deletion
    }
  }

  //increment visit of a corridor
  async function visitLog(corridorId){
    const creator = await getUserTag();

    const docRef = doc(db, '/corridors/', corridorId);

    //adds one to the current number of visit
    const docSnap = await getDoc(docRef);
    const corridor = docSnap.data();
    if (corridor.creatorTag == creator){
      //visitor is creator
      return
    } else if (corridor.creatorTag !== creator) {
      const visit = docSnap.data().numberOfVisit + 1;
      //set the field
      await updateDoc(docRef, {'numberOfVisit':visit})
    }
    return
  }


// --------------------------------------------------------
//Storage
// --------------------------------------------------------
  import {getStorage, ref, getDownloadURL, uploadBytes, deleteObject} from "firebase/storage";
  const storage = getStorage();
  async function uploadImage(theFile, path){
    //const storageRef = ref(storage, (path + '/test/' + theFile.name));
    const storageRef = ref(storage, (path + theFile.name));
    await uploadBytes(storageRef, theFile);
    const imgURL = await getDownloadURL(storageRef);
    return imgURL
  }

  //"https://firebasestorage.googleapis.com/v0/b/corridor-5353b.appspot.com/o/images%2Ftest%2FPXL_20230910_230658314.jpg?alt=media&token=c49658f7-f098-43cf-b3ab-e51335596a60
  async function deleteImage(imgURL) {
    try {
      // Extract the path from the download URL to create a storage reference
      const path = imgURL.substring(imgURL.indexOf('/images/'));
      const storageRef = ref(storage, path);
      // Delete the file from storage
      await deleteObject(storageRef);

      return true; // Successful deletion
    } catch (error) {
      return false; // Failed deletion
    }
  }


// --------------------------------------------------------
//Authentification
// --------------------------------------------------------
  import { getAuth, signInWithPopup, signInWithRedirect, GoogleAuthProvider, onAuthStateChanged} from "firebase/auth";

  const provider = new GoogleAuthProvider();
  const auth = getAuth();

  async function googleSignIn(){
    return await signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      }).catch((error) => {
        console.log(error)
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  }

  async function userInDb(uid) {
    const docRef  = doc(db, "/userids/", uid);
    const docSnap = await getDoc(docRef);
    return docSnap.exists()
  }
  async function tagInDb(tag){
    try {
      const docRef = doc(db, '/usertags/', tag);
      const theDoc = await getDoc(docRef);
      return theDoc.exists();  
    }
    catch(err){
      return
    } 
  }

  async function addUserToDb(uid, usertag){
    uid = String(uid);
    //add user id
    await setDoc(doc(db, "/userids/", uid), {'usertag': usertag});
    //add usertag
    await setDoc(doc(db, '/usertags/', usertag), {'userId': uid});
  }

  async function getUserTag(){
    if(auth.currentUser){
      //get the logged in user tag
      const id = auth.currentUser.uid;
      const docRef = doc(db, '/userids/', id);
      const docSnap = await getDoc(docRef);
      const tag  = docSnap.data().usertag;
      return tag
    } else {
      return null
    }
  }





//-----------------------------------------------------------------
export {
  publishCorridor, deleteCorridor,
  fetchCorridor, fetchAllCorridors, fetchTagedCorridor, 
  visitLog,
  uploadImage, deleteImage,
  googleSignIn, auth, onAuthStateChanged, 
  addUserToDb, userInDb, tagInDb, getUserTag
}