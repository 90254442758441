<template>
	<p id="topText"
		v-if="!showSignIn && isLogged && userExist"
		>Create or edit your corridors <i id="creator">{{tag}}</i>
	</p>

	<!-- Create -->
	<router-link
		id='createButton'
		class='button'
		to="/create"
		v-if="!showSignIn && isLogged && userExist"
		> Create a new Corridor
	</router-link>

	<Explorer
		:key="rerender"
		:who='tag' 
		v-if="!showSignIn && ready && isLogged"
		:edit="true"
		/>
	<Auth 
		v-if="showSignIn"
		:logged="isLogged"
		@close="onSignInClose"
		/>

	<!-- Log Out -->
	<div
		id="logOutBut"
		class="button"
		v-if="isLogged"
		@click="logOut"
		>Log Out
	</div>

</template>
<script type="text/javascript">
	import Explorer from '@/components/explorer/Explorer.vue'

	import Auth from '@/components/Auth.vue'

 	import {getUserTag, auth, userInDb} from '@/firebase/firebase.js'

	export default{
		name: 'YouView',
		components: {
			Explorer,
			Auth, 
		},
		data(){
			return {
				showSignIn: false,
				tag: null,
				showSignIn: null,
				isLogged: null,
				ready: false,//when user tag is fetched
				userExist: false,
				rerender: false, //for rerendering Explorer comp
			}
		},
		methods: {
			async onSignInClose(){
				await auth.onAuthStateChanged(async (user) => {
				  if (user) {
				    this.userExist = await userInDb(user.uid)
				    if(this.userExist){
				    	this.showSignIn = false;
				    }
				  } else {
				    this.$router.back();
				  }
				});
			},

			async logOut(){
				await auth.signOut();
				this.isLogged = await(auth.currentUser!==null);
			}
		},
		async beforeCreate(){
			await auth.onAuthStateChanged(async (user) => {
			  if (user) {
			    this.isLogged = true;

			    this.userExist = await userInDb(user.uid)
			    if (this.userExist){
			    	//in db has tag
			    	this.tag = await getUserTag();
			    	this.ready = true;
			    	this.showSignIn = !this.isLogged;
			    } else{
			    	//user not in db
			    	this.showSignIn = true;
			    }
			    
			  } else {
			    this.isLogged   = false;
			    this.showSignIn = true;
			  }

			});
		},
		watch: {
		  '$route.query.reload': function(newValue) {
		    if (newValue) {
		      this.rerender = !this.rerender; // toggle to trigger re-render
		    }
		  }
		}
	}
</script>

<style type="text/css" scoped>

	#topText{
		text-align: center;
		font-size: 3.4vh;
		margin-top: 1vh;
		font-weight: bold;

	}

	#creator{
		text-decoration: underline;
		font-style: normal;
	}
	#createButton{
		width: 55%;
		position: relative;
		left: 22.5%;
		margin-top: 3vh;
	}

	#logOutBut{
		background-color: red;
		width: 22%;
		margin: 3.4vh auto;
		position: relative;
		bottom: 4vh;
	}
</style>