<template>
	<div id="authBox">
		<h1 id="salutation">Bienvenue</h1>
		<!-- Login -->
		<div id="login" v-if="showLogin && !isLogged">

			<p class="googleLoginBut" @click="onGoogle">
				Continue With : 
						<i>
							<img id="googleLogo" src="@/assets/google.png">
						</i>
			</p>

			<div id="emailSign">
				<span id="InUpSelect">
					<p class="choix" :class="{ 'selected': UpOrIn === 'in' }" @click="UpOrIn = 'in'">SignIn</p>
					<p class="choix" :class="{ 'selected': UpOrIn === 'up' }" @click="UpOrIn = 'up'">SignUp</p>
				</span>

				<!-- //email -->
				<label for="email">Email:</label>
				<input type="email" id="email" v-model="email">
				<!-- password -->
				<label for="password">Password:</label>
				<input type="password" id="password" v-model="pw" @keyup.enter="onNext">

				<!-- Proceed -->
				<p class="button" @click="onNext" v-if="UpOrIn === 'in'">Login</p>
				<p class="button" @click="onNext" v-if="UpOrIn === 'up'">Create Account</p>
			</div>
		</div>

		<!-- User Tag -->
		<div id="userTag" v-if="showCreateTag && logged">
			<h3 class="title">Create your User Tag:</h3>
			<input 
				class="tagInput"
				type="text"
				maxlength="22" 
				v-model="newUserTag"
				@keyup="checkUserTag"
			>
			<!-- BADDD -->
			<p v-if="tagToSmall" class="tagCheck badTag">At least 3 characters</p>
			<p v-if="tagMax" class="tagCheck badTag">MAX 22 characters!</p>
			<p v-if="!tagOk && !tagToSmall" class="tagCheck badTag"><i class="theTag">{{newUserTag}}</i> is not available</p>
			<!-- GOOODDD -->
			<p v-if="tagOk && !tagToSmall" class="tagCheck"><i class="theTag">{{newUserTag}}</i> is a Beautiful User Tag!</p>


			<div
				class="button"
				id="addTagButton"
				@click="addUserTag()"
				v-if="tagOk"
			>Create
			</div>
		</div>

		<!-- Log Out -->
		<div
			id="logOutBut"
			class="button"
			v-if="isLogged && !showCreateTag"
			@click="logOut"
			>Log Out
		</div>

		<!-- close button -->
		<i 
			@click="onClose()"
			class="button fa fa-window-close fa-xl"
			id="closeButton"
			></i> 

	</div>
	
</template>

<script type="text/javascript">
	import {auth, onAuthStateChanged, googleSignIn, addUserToDb, userInDb, tagInDb} from '@/firebase/firebase.js'
	import {createUserWithEmailAndPassword, signInWithEmailAndPassword} from "firebase/auth";

	export default{
		name: 'Auth',
		emits: ['close'],
		props: {
			logged: Boolean,
		},
		data(){
			return{
				email: '',
				pw:    '',
				showLogin:     true,
				showCreateTag: false,
				newUserTag:    null,
				tagOk:    false,
				isLogged: null,
				userId:   null,
				//if the google pop up is open no double click ...
				googleOpen: false,
				tagToSmall: true,
				tagMax: false,
				UpOrIn: 'up',
			}
		},
		methods:{
			async onNext(){
				//for email sign in
				//sign up
				if (this.UpOrIn == 'up'){
					await createUserWithEmailAndPassword(auth, this.email, this.pw)
					  .then(async (userCredential) => {
					    // Signed up 
					    const user = userCredential.user;

					    this.userId = auth.currentUser.uid;
					    //check if user already exist if yes quit login
					    const userExist = await userInDb(this.userId);

					    if (userExist) {
					    	this.$emit('close')
					    } 
					    else if(!userExist){
					    	this.showCreateTag = true;
					    }
					  })
					  .catch((error) => {
					    if (error.code === 'auth/email-already-in-use'){
					    	alert('email already used please Login');
					    	this.UpOrIn = 'in';
					    	this.pw = '';
					    }
					  });
				}
				//signIn
				if (this.UpOrIn == 'in') {
					signInWithEmailAndPassword(auth, this.email, this.pw)
					  .then((userCredential) => {
					    // Signed in 
					    const user = userCredential.user;
					  })
					  .catch((error) => {
					    const errorCode = error.code;
					    const errorMessage = error.message;
					  });
				}
			},

			async onGoogle(){
				//for google sign in
				if(this.googleOpen){return}

				this.googleOpen = true;

				await googleSignIn().then(async () => {
				  this.userId = auth.currentUser.uid;
				  //check if user already exist if yes quit login
				  const userExist = await userInDb(this.userId);

				  if (userExist) {
				  	this.$emit('close')
				  } 
				  else if(!userExist){
				  	this.showCreateTag = true;
				  }
				});
			},

			onClose(){
				//on close button or close page 

				//check if tag is to be entered
				if (this.showCreateTag) {
					//tag must be entered
					window.alert("You MUST enter a tag to exist")
					return 
				} else {
					this.$emit('close')
				}
			},

			async checkUserTag(){
				const tag = this.newUserTag.toLowerCase();

				this.tagToSmall = tag.length<3;
				this.tagMax	 	= tag.length==22;

				if(!this.tagMax && !this.tagToSmall){
					//check if is not in db
					this.tagOk = !(await tagInDb(tag))	
				} else {
					this.tagOk = false;
				}
			},
			async addUserTag(){
				console.log(this.userId)
				if(this.userId!==null){
					await addUserToDb(this.userId, this.newUserTag)
					this.$emit('close')
				}
			},

			async logOut(){
				await auth.signOut();
				this.isLogged = await(auth.currentUser!==null);
			},
		},
		created() {
		  auth.onAuthStateChanged((user) => {
		    if (user) {
		      this.isLogged = true;
		      this.userId = user.uid
		    } else {
		      this.isLogged = false;
		    }
		  });

		  //check if user logged from youViewPage for if tag as been created ... clearness
		  if (this.logged){
		  	this.showLogin 	 = false;
		  	this.showCreateTag = true;
		  };
		},
	} 
	
</script>

<style type="text/css" scoped>
	#authBox{
		position: fixed;
		top: 22.5vh;
		right: 33vw;

		width: 34vw;
		height: 55vh;
		background-color: var(--col1);
		border: 3px solid black;
		display: flex;
		flex-direction: column;
	}

	#salutation{
		text-decoration: underline;
		text-align: center;
		margin: 2.22vh;
	}

	#login{
		height: 100%;
		align-self: center;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		text-align: center;
	}
	.googleLoginBut{
		font-size: 2.22vh;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 2px solid blue;
		border-radius: 15px;
		background-color: white;
		color: black;
		cursor: pointer;
		font-family: Product sans;
		font-weight: bold;

		padding: .69vh;
	}
	#googleLogo{
		cursor: pointer;
		height: 55px;
		width: 55px;
		margin-left: 2.22vw;
	}
	.googleLoginBut:hover{
		transform: scale(1.069);
	}

	#InUpSelect{
		display: inline-flex;
		justify-content: space-around;
		margin-bottom: 2vh;
	}
	.choix{
		border: 2px solid white;
		padding: 1.22vh;
		font-weight: bold;
		cursor: pointer;
	}
	.selected{
		background-color: white;
	}
	#emailSign{
		display: flex;
		flex-direction: column;
	}
	#emailSign input{
		padding: 1vh;
		font-size: 2.22vh;
	}
	#emailSign .button{
		width: 22vw;
		align-self: center;
		margin-top: 2vh;
	}
	#emailSign label{
		font-size: 2.2vh;
		font-weight: bold;
	}

	#logOutBut{
		background-color: red;
		justify-self: center;
		align-self: center;
		position: relative;
		top: 34%;
	}

	#userTag {
		height: 66%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
	}
	.tagInput{
		width: 34%;
		margin-top: 1.22vh;
		align-self: center;
		font-weight: medium;
		font-size: 3.4vh;
		text-align: center;
	}
	.tagCheck{
		font-size: 2.22vh;
		margin-top: 1.22vh;
	}
	.badTag {
		color: red;
	}
	.theTag{
		text-decoration: underline;
		font-style: normal;
		font-size: 122%;
	}
	#addTagButton{
		width: 22%;
		align-self: center;
		margin-top: 3vh;
	}

	#closeButton{
		z-index: 4;
		font-weight: bold;
		border: none;

		position: absolute;
		top: 1.55vh;
		right: 1.55vh;

		user-select: none;
		font-size: 4.22vh;
	}


	@media only screen and (max-width: 922px){
		#authBox{
			position: fixed;
			width: 89%;
			left:4.20%;

			height: 66%;
			top: 17%;
		}
		#googleLogo{
			height: 6vh;
			width: 6vh;
		}
	}
</style>