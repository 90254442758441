<template>
	<div id="carousel">
		<AWall
			v-if="walls[prevIndex]"
			:canFullScreen="false"
			id='leftWall'
			class='wall sideWall'
			:theWall='walls[prevIndex]'
			@click = "changeWall('prev')"
			/>
		<AWall
			:canFullScreen="true" 
			id='centerWall'
			class='wall'
			:theWall='walls[curIndex]'

			/>
		<AWall
			v-if="walls[nextIndex]"
			:canFullScreen="false"
			id='rightWall'
			class='wall sideWall'
			:theWall='walls[nextIndex]'
			@click = "changeWall('next')"
			/>
	</div>
</template>
<script type="text/javascript">
	import AWall from '@/components/viewer/AWall.vue'

	export default {
		name: 'CarouselViewer',
		components: {
			AWall,
		},
		props: {
			walls: Array,
		},
		data(){
			return {
				numberOfWalls: 0,
				prevIndex: null,
				curIndex:  0,
				nextIndex: 1,
				touchStartX :0,
				touchEndX 	:0,
			}
		},
		methods: {
			changeWall(direction){
				//if only one wall
				if (this.numberOfWalls===0){
					return
				}
				//if 2 wall
				if(this.numberOfWalls === 1){
					if(this.curIndex === 1){
						this.curIndex  = 0;
						this.prevIndex = 1;
						this.nextIndex = 1;
					}
					else{
						this.curIndex  = 1;
						this.prevIndex = 0;
						this.nextIndex = 0;
					}
					return
				}
				if (direction==='next') {
					if(this.curIndex === this.numberOfWalls){
						this.prevIndex = this.numberOfWalls;
						this.curIndex  = 0;
						this.nextIndex ++;
					}
					else if(this.nextIndex === this.numberOfWalls){
						this.prevIndex ++;
						this.curIndex  ++;
						this.nextIndex = 0;
					}
					else if(this.prevIndex === this.numberOfWalls){
						this.prevIndex = 0;
						this.curIndex  ++;
						this.nextIndex ++;
					}
					else{
						this.prevIndex ++;
						this.curIndex  ++;
						this.nextIndex ++;
					}
					return

				}
				else if (direction==='prev') {
					if(this.curIndex === 0){
						this.prevIndex --;
						this.curIndex  = this.numberOfWalls;
						this.nextIndex     --;
					}
					else if(this.nextIndex === 0){
						this.prevIndex --;
						this.curIndex  --;
						this.nextIndex 	   = this.numberOfWalls;
					}
					else if(this.prevIndex === 0){
						this.prevIndex = this.numberOfWalls;
						this.curIndex  --;
						this.nextIndex 	   --;
					}
					else {
						this.prevIndex --;
						this.curIndex  --;
						this.nextIndex 	   --;
					}
					return
				}
				return
			},
			//for touch screen
			onTouchStart(event){
				this.touchStartX = event.changedTouches[0].screenX;
			},
			onTouchEnd(){
				this.touchEndX = event.changedTouches[0].screenX;
			    this.CatchSwipe(this.touchStartX, this.touchEndX);
			},
			CatchSwipe(touchStartX, touchEndX){
				if (touchEndX > touchStartX) {	
					this.changeWall('prev')
				}
			    else if (touchEndX < touchStartX) {
			        this.changeWall('next')
			    }
			},
			CatchArrows(event){
				var name = event.key;
				if (name==='ArrowRight'){
					this.changeWall('next');
				}
				else if (name === 'ArrowLeft'){
					this.changeWall('prev');
				}else{}
			},
		},
		mounted(){
			/*for touch screenn only*/
			document.getElementById("carousel").addEventListener('touchstart', (event) => {
				this.onTouchStart(event)
			}, false);
			document.getElementById("carousel").addEventListener('touchend', (event) => {
				this.onTouchEnd(event)
			}, false);

			/*on wheel roll to chage current wall*/
			document.getElementById("carousel").addEventListener('wheel', (event) => {
				setTimeout(() => {
					    if(event.deltaY < 1){
					    	this.changeWall('next')
					    }
					    else if(event.deltaY > 1){
					    	this.changeWall('prev')
					    };		
			    	},0)
			});

			//side aroww to switch walls
			window.addEventListener('keydown', (event) => {
				this.CatchArrows(event)	
			})
		},
		created(){
			this.numberOfWalls = this.walls.length-1
			this.prevIndex = this.numberOfWalls;
			//if only one wall
			if(this.prevIndex===0){this.prevIndex=null}
		},
	}
</script>

<style type="text/css" scoped>
	#carousel{
		display: flex;
		padding-bottom: 2.2vh;

		justify-content: center;
		overflow-x: hidden;
		overflow-y: hidden;
		height: 100%;
	}
	.wall{
		position: relative;
		height: 89%;
		margin: 3vw;
	}
	.sideWall{
		opacity: 44%;
		cursor: pointer;
	}
	.sideWall:hover{
		transform: scale(.96);
	}
	#centerWall{
		min-width: 70vw;
	}
	#rightWall{
		min-width: 60vw;
		right: 5vw;
	}
	#leftWall{
		min-width: 60vw;
		left: 5vw;
	}
	@media only screen and (max-width: 700px){
		.sideWall{
			display: none;
		}
		#currentWall{
			width: 89%;
		}
	}

</style>