<template>
  <div id="creationPage" v-if="!showPreview && !showSignIn && okToEdit">
    <!-- publish -->
    <div
      id="topPublishButton"
      class='button publishButton'
      @click='onPublishCorridor'
    > {{publishText}} 
    </div>

    <!-- Delete Corridor -->
    <p 
      id="deleteCorridor"
      v-if="!isVirgin"
      class="button"
      @click="deleteClicked"
      >Delete Corridor
    </p>

    <!-- corridor Title -->
    <input
      id="titleInput"
      class="input"
      type="text" 
      maxlength="55" 
      v-model="corridor.title" 
      placeholder="Corridor Title"
      ref="titleInput"
      @keydown.enter="closeKeyboard"
      @input="changed=true"
    >
    <p id="creator">By: {{corridor.creatorTag}}</p>

    <!-- TOGGLER -->
    <span id="togglers">
      <!-- Private -->
      <span class="publicLabel">
        Public : 
        <label class="switch">
          <input type="checkbox" v-model="corridor.isPublic" @input="changed=true">
          <span class="slider round"></span>
        </label>
      </span>

      <!-- Oldest first // the order of the walls -->
      <span class="publicLabel">
        Newest wall first : 
        <label class="switch">
          <input type="checkbox" v-model="corridor.oldestFirst" @input="toggleOrder()">
          <span class="slider round"></span>
        </label>
      </span>
    </span>

    <div
      id='addButton'
      class='button'
      v-if="this.corridor.oldestFirst"
      @click='newWall()'
    >Add a Wall
    </div>

    <!-- Tags -->

    <!-- The walls -->
    <CreationCard
      v-for="(wall, index) in corridor.walls"
      :key="wall"
      :wall="wall"
      :index="index"
      @imageChanged="changeImage($event, index)"
      @changedTitle="changeTitle($event, index)"
      @isUploading="{isUploading = !isUploading; notUploaded=false;}"
      @notUploaded="(notUploaded = !notUploaded)"
      @deleted="removeWall(index)"
    />

    <div
      id='addButton'
      class='button'
      v-if="! this.corridor.oldestFirst"
      @click='newWall()'
    >Add a Wall
    </div>
    <!-- preview -->
    <div
      id='showPrevButton'
      class='button'
      @click='onShowPreview'
      >Preview Corridor
    </div>
    <!-- publish -->
    <div
      id="bottomPublishButton"
      class='button publishButton '
      @click='onPublishCorridor'
    > {{publishText}} 
    </div>
  </div>

  <!-- Corridor Preview -->
  <Viewer
    v-if  = 'showPreview'
    :corridor=corridor
    @close= 'onClosePreview'
    :isPreview= 'true'
    fromSomewhere='true'/>

    <!-- Authentification if not logged in -->
  
  <!-- Log user in -->
  <Auth 
    v-if="showSignIn" 
    @close="onCloseSignIn()"/>

  <!-- sure to delete ? -->
  <Sure 
    v-if="showSure"
    what="Corridor"
    @no="showSure=false"
    @yes="deleteCorridor"
  />

</template>

<script>
  import CreationCard from '@/components/CreationCard.vue'
  import Viewer from '@/components/viewer/Viewer.vue'
  import Sure from '@/components/utils/Sure.vue'

  import Auth from '@/components/Auth.vue'

  import {publishCorridor, fetchCorridor, deleteCorridor, auth, onAuthStateChanged, getUserTag, uploadImage, deleteImage} from '@/firebase/firebase.js'

  import Corridor from '@/classes/corridorClasse.js'
  import cloneDeep from 'lodash/cloneDeep';

  export default {
    name: 'CreationView',
    components: {
      CreationCard,
      Viewer,
      Auth,
      Sure,
    },
    props: [
       'corridorId',
    ],
    data(){
      return {
        corridorOriginel: Object, //for check modifications
        corridor:    Object,
        isVirgin:    null,
        showPreview: false,
        publishText: "Save Changes", //text for the publish button
        showSignIn:  null,
        isLogged:    null,
        okToEdit:    false, //okToEdit to edit
        isUploading: false, //sent from creation cord
        isPublishing: false, //to ensure no double publishhhh
        showSure: false, //sure to delete
        changed:  false,
        notUploaded: false, //if an image as been selected but its not uploaded
        deletion: false,

        newImagesFile: {}, //images that have changed
        deletedImagesURL: [],
      }
    },
    methods: {
      //when add button is clicked blank wall
      newWall(){
        if (this.corridor.oldestFirst){
          this.corridor.walls.unshift({
            title: '', 
            imgURL: null, 
            description: "",
          })
        } else {
          this.corridor.walls.push({
            title: '', 
            imgURL: null, 
            description: "",
          })
        }
      },

      changeImage(file, index){
        this.newImagesFile[index] = file;
        this.changed = true;
      },
      changeTitle(newTitle, index){
        this.corridor.walls[index].title = newTitle;
        this.changed = true;
      },
      removeWall(index){

        if(this.corridor.walls[index].imgURL){
          //will be deleted on save changes
          this.deletedImagesURL.push(this.corridor.walls[index].imgURL);
        }

        //remove a wall at a given index after the delete wall as been clicked in card
        this.corridor.walls.splice(index,1)
        this.changed = true;
      },

      toggleOrder(){
        this.corridor.oldestFirst = !this.corridor.oldestFirst;
        this.changed = true;
        this.corridor.walls.reverse()
      },

      deleteClicked(){
        //when delete button is clicked verify deletion
        this.showSure = true;
      },
      deleteCorridor(){
        //firebase will delete the document
        this.deletion = deleteCorridor(this.corridor);

        if (this.deletion){
          //deletion succesful
          window.alert("Succesfully Deleted This Corridor.");

          window.removeEventListener('beforeunload', this.handleBeforeUnload);

          //go back to you
          this.$router.push({ path: '/you', query: { reload: true } });
        }
      },

      async onPublishCorridor(){
        if (this.isPublishing) {
          return
        }
        //si pas titre
        if(this.corridor.title == null){
          alert('It needs a title');
          return
        };
        //if no walls
        if(this.corridor.walls.length == 0){
          alert("!!--A corridor without walls can't exist-!!");
          return
        }

        if (this.changed) {
            this.isPublishing = true;
            this.publishText = "Wait a Bit ... Wall Building in progress ";
            // animate publish button top and bottom
            document.getElementById('bottomPublishButton').classList.add('scale-and-back');
            document.getElementById('topPublishButton').classList.add('scale-and-back');


            //upload new images
            // Array to store promises for image uploads
            const uploadPromises = [];
            // Iterate over new images and initiate uploads
            for (let key in this.newImagesFile) {
              if (this.corridor.walls[key]) {
                const path = '/images/real/' + this.corridor.creatorTag + '/' + this.corridor.corridorId + '/';
                uploadPromises.push(uploadImage(this.newImagesFile[key], path));
              }
            }
            // Wait for all image uploads to complete
            const imageUrls = await Promise.all(uploadPromises);
            // Update the imgURLs of the walls corresponding to the keys in newImagesFile
            Object.keys(this.newImagesFile).forEach((key, index) => {
              this.corridor.walls[key].imgURL = imageUrls[index];
            });

            //delete unused images
            // Array to store promises for image deletions
            const deletePromises = [];
            // Delete unused images from firebase
            this.deletedImagesURL.forEach(url => {
              deletePromises.push(deleteImage(url));
            });
            // Wait for all image deletions to complete
            await Promise.all(deletePromises);

            //publish corridor to firestore
            const newId = await publishCorridor(Object.assign({}, this.corridor), this.isVirgin);
            this.corridor.corridorId = newId;
            this.changed = false;

            // stop animate publish button
            document.getElementById('bottomPublishButton').classList.remove('scale-and-back');

            // Go to the corridor viewer URL
            this.$router.push('/view/' + newId + '/1');
            this.isPublishing = false;

          } else {
            // No changes
          }

        //go to the corridor viewer URL
        this.$router.push('/view/'+this.corridor.corridorId+'/1');
        return
      },

      onCloseSignIn(){
        auth.onAuthStateChanged((user) => {
          if (user) {
            this.isLogged = true;
          } else {
            this.isLogged = false;
          }
        });

        if(this.isLogged){
          this.showSignIn=false  
        }
        else if(!this.isLogged){
          this.$router.push('/')
        }
      },
      onShowPreview(){
        //if an image is still uploading
        if (this.isUploading){
          alert("Wait a bit something is still uploading");
          return
        }
        this.showPreview = true;
      },
      onClosePreview(){
        this.showPreview = false;
      },
      closeKeyboard(){
        this.$refs.titleInput.blur();
      },

      //alert so no lost when reload page or close tab
      handleBeforeUnload(event) {
          if (this.changed && !this.deletion) {
            const message = 'You have unsaved changes. Are you sure you want to leave?';
            event.returnValue = message; // Standard for most browsers
            return message; // For some older browsers
          }
        },
    },

    mounted(){
      window.addEventListener('beforeunload', this.handleBeforeUnload);
    },
    beforeDestroy() {
      window.removeEventListener('beforeunload', this.handleBeforeUnload);
    },

    async beforeCreate(){
      //verify login and fetch/create corridor
      await auth.onAuthStateChanged(async (user) => {
        if (user) {
          this.isLogged = true;
          this.isVirgin = (this.corridorId === '');
          const currentUserTag = await getUserTag();

          if (!this.isVirgin) {
            this.corridor = await fetchCorridor(this.corridorId)
            this.corridorOriginel = cloneDeep(this.corridor);
            
            //check if the logged user is the creator of the corridor
            if (this.corridor.creatorTag === currentUserTag){
              this.publishText = "Save Changes";
              this.okToEdit = true;
              this.showPreview = false;
              return
            }
            else {
              this.okToEdit = false;
              this.$router.go(-2);
              window.alert("Don't Touch What isn't YOURS!");
              return
            }
          }
          else if (this.isVirgin){
            this.okToEdit = true;
            this.corridor = new Corridor();
            this.corridorOriginel = cloneDeep(this.corridor);
            this.corridor.creatorTag = currentUserTag;
            this.publishText = "Publish Corridor";
            return
          } 
          else {
            console.log("Shit")
          };
        } else {
          this.isLogged = false;
        }
        this.showSignIn = !this.isLogged;
      });
    },

    beforeRouteLeave(to, from, next) {
        if (this.changed && !this.deletion) {
          // Display a confirmation dialog
          const confirmMessage = 'You have unsaved changes. Are you sure you want to leave?';
          if (window.confirm(confirmMessage)) {
            // User clicked OK, proceed with navigation
            window.removeEventListener('beforeunload', this.handleBeforeUnload);
            next();
          } else {
            // User clicked Cancel, stay on the current page
            next(false);
          }
        } else {
          // No changes, proceed with navigation
          window.removeEventListener('beforeunload', this.handleBeforeUnload);
          next();
        }
    },
  }
</script>

<style scoped>
  #creationPage{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  #deleteCorridor{
    position: absolute;
    top: 2vh;
    left: 2vh;

  }
  #deleteCorridor:hover{
    border-color: red;
    font-weight: bold;
  }

  #titleInput{
    margin-top: 9vh;
  }
  #titleInput:focus{
    border: .66vh solid var(--col1);
  }

  #creator {
    font-size: 2.22vh;
    margin: .22vh;
  }

  #addButton{
    border: .34vh solid var(--dark);
    padding: .34vh;
  }
  .publishButton{
    position: relative;
    z-index: 4;
    background-color: var(--light);
    border-color: var(--col1);
    font-weight: bold;
  }
  #topPublishButton{
    position: absolute;
    top: 2vh;
    right: 2vh;
  }
  #bottomPublishButton{
    width: 55vw;
    margin: 2.22vh;
  }



  #togglers{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /*slider from W3School*/
  .publicLabel{
    font-size: 3vh;
    margin: 1vh;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  input:checked + .slider {
    background-color: var(--col1);
  }
  input:focus + .slider {
    box-shadow: 0 0 1px var(--col1);
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
  @keyframes scaleAnimation {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
  }
  .scale-and-back {
    animation: scaleAnimation 2s infinite;
  }


  /*on Mobile phones*/
  @media only screen and (max-width: 700px){
    #togglers{
      flex-direction: row;
      overflow: hidden;
    }
    .publicLabel{
      font-size: 2.22vh;
      margin: 1vh;
    }
    .slider:before{
      height: 15px;
      width: 15px;
    }
    .switch{
      height: 24px;
      width: 37px;
    }
    input:checked + .slider:before {
      -webkit-transform: translateX(15px);
      -ms-transform: translateX(15px);
      transform: translateX(15px);
    }
  }
</style>
