<template>
	<!-- for full screen when one is clicked -->
	<div id="wallContainer" v-if="wallVisible" @click="wallVisible=false">
		<AWall
			class = "displayedWall"
			:theWall='wallToShow'
			canFullScreen="true"
			showClose='true'
			@close='wallVisible=false'
			@click.stop
		/>
	</div>

	<!-- a grid with squared images of the walls when a square is hovered the walll is displayed -->
	<div id="gridViewer">
		<div
			class="cell"
			v-for="wall in walls"
			@click="showWall(wall)">
			<img
				:src="wall.imgURL"
				alt="Image in"
			/>
		</div>
	</div>
</template>

<script type="text/javascript">
	import AWall from '@/components/viewer/AWall.vue'

	export default {
		name: 'GridViewer',
		components: {
			AWall
		},
		props: {
			walls: Array,
		},
		data(){
			return {
				wallVisible: false,
				wallToShow: {},
			}
		},
		methods: {
			showWall(wall){
				this.wallToShow = wall;
				this.wallVisible = true;
			},
		},
	}

</script>

<style type="text/css" scoped>
	#gridViewer {
	  display: grid;
	  grid-template-columns: repeat(3, 1fr); 
	  gap: 10px; /* gap between images */
	  padding: 2.22vh;

	}

	.cell {
	  width: 100%;
	  box-sizing: border-box;
	  display: flex;
	  justify-content: center; /* Center horizontally */
	  align-items: center; /* Center vertically */

	  
	}
	.cell:hover {
		cursor: pointer;
	}

	.cell img {
	  width: 100%;
	  height: auto;
	  border: solid white .5px;
	}

	#wallContainer{
		position: fixed;
		width: 100vw;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.69);;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 4;
	}

	/*Mobile*/
	@media only screen and (max-width: 1200px) {
	  #gridViewer {
	    display: grid;
	    grid-template-columns: repeat(2, 1fr); /* Adjust the grid layout as needed */
	    gap: 1.22vw; /* Adjust the gap between images */
	  }
	}
	/*not mobile*/
	@media only screen and (min-width: 1200px) {
	  .aWall{
		width: 55vw;
		height: 89vh;
	  }
	}

</style>