<template>
	<Viewer 
		:corridor='corridor'
		:isPreview='false'
		v-if="!problem"
	/>

	<h1 v-if="problem"> Sorry there was a problem fetching that corridor; We've hitted a wall ... Comme le gars qui voulait rentrer dans la police.  </h1>
</template>

<script type="text/javascript">
	import Viewer from '@/components/viewer/Viewer.vue'
	import {fetchCorridor, visitLog} from '@/firebase/firebase.js'
	import Corridor from '@/classes/corridorClasse.js'

	export default{
		name: 'ViewerView',
		components: {
			Viewer
		},
		props:{
			id: String, 
		}, 
		data(){
			return {
				corridorId: this.id,
				corridor: Object(),
				problem: false,
			}
		},
		async beforeMount(){
			//fetch corridor from firebase 
			this.corridor  = await fetchCorridor(this.id);

			if(this.corridor=== undefined){
				this.problem = true;
			}

			//change page title
			document.title = this.corridor.title;

		},
		mounted(){			
			visitLog(this.id)
		}
	}
</script>